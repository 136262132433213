* {
  box-sizing: border-box;
}
html {
  font-size: $baseFontSize;
}
.noBorder {
  border: none !important;
}
.textCenter {
  text-align: center !important;
}
.displayFlex {
  display: flex;
}
.noWrap {
  white-space: nowrap;
}
.alignCenter {
  align-items: center;
}

.column {
  flex-direction: column;
}
@mixin treeNode() {
  width: 100%;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    .nodeIcon {
      width: 20px;
      height: 20px;
      float: left;
      cursor: pointer;
    }
    .emptyNodeIcon {
      width: 20px;
      height: 20px;
      float: left;
    }
    .nodeName {
      border-width: 1px;
      border-style: solid;
      width: fit-content;
      padding: 5px;
      border-radius: 3px;
      .nodeText {
        overflow-wrap: break-word;
      }
      .icons {
        padding-left: 5px;
      }
    }
  }
}
@mixin licenseExtender() {
  .licenseExtender {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    border-width: 1px;
    border-style: solid;
    width: fit-content;
    margin: 2px;
    .firstRow {
      display: flex;
      align-items: center !important;
      justify-content: center !important;
      width: 100% !important;
      .leftArrow {
        float: left;
        width: 10%;
      }
      .yearRange {
        text-align: center;
        width: 80%;
      }
      .rightArrow {
        float: right;
        text-align: right;
        width: 10%;
      }
    }
    .secondRow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .year {
        border-radius: 2px;
        border-style: solid;
        border-width: 1px;
        padding: 5px;
        margin: 5px;
      }
    }
  }
}
@mixin clickable() {
  cursor: pointer;
}
@mixin radioField() {
  .radioField {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px;
    margin: 5px;
    .checkbox {
      width: 20px !important;
      height: 20px !important;
      border-style: solid;
      border-width: 2px;
      padding: 2px;
      margin-right: 5px;
      border-radius: 4px;
      @include clickable();
    }
    .textValue {
      padding-left: 2px;
    }
  }
}
@mixin backgroundOverlay($position: absolute, $background: transparent) {
  .backgroundOverlay {
    background: $background;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: $position;
    z-index: 1000;
  }
}
::-webkit-scrollbar {
  width: 10px;
}
body {
  h3 {
    padding-top: 10px;
  }

  .align-left {
    text-align: left;
  }
  .activeOutline {
    border-width: 1px;
    border-style: solid;
    font-weight: bold;
  }
  .align-center {
    text-align: center;
  }
  .align-right {
    text-align: right;
  }
  .right {
    float: right;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .noListStyle {
    list-style: none;
  }
  margin: 0;
  .link:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
  .link {
    text-decoration: none;
  }
  @include backgroundOverlay($position: fixed, $background: transparent);
  .loadable {
    position: relative;
    @include backgroundOverlay($position: absolute, $background: transparent);
  }
  /* Tooltip container */
  .toolTip {
    position: relative;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
  }

  .toolTip.definition {
    display: inline;
    border-bottom: 1px dashed;

    .toolTipFullText {
      top: 20px;
      left: -30px;
      max-width: 200px;
      white-space: pre-wrap;
    }
  }

  /* Tooltip text */
  .toolTip .toolTipFullText {
    visibility: hidden;
    min-width: 100px;
    width: max-content;
    max-width: 300px;
    text-align: center;
    padding: 5px;
    position: absolute;
    top: 40px;
    z-index: 1;
    transition-delay: 1s linear;
    border-radius: 2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.65);
  }
  /* Show the tooltip text when you mouse over the tooltip container */
  .toolTip:hover .toolTipFullText {
    visibility: visible;
  }
  .activeNode {
    border-width: 2px !important;
    border-left-width: 15px !important;
  }
  .scrollable {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .loaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      display: flex;
      position: absolute;
      top: 30%;
      left: 45%;
      right: 0;
      bottom: 0;
      transform: translate(-25%, -25%);
      z-index: 1001;
    }
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0px;
    border-style: solid;
    border-width: 1px;
    .accordionTitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
    }
    .accordionBody {
      padding: 5px;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .radioButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding-left: 0px;
    @include radioField();
    .radioButton {
      border-radius: 20px;
      width: 20px;
      height: 20px;
    }
  }
  @include radioField();
  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .clickable {
    @include clickable();
  }

  .leftSpacingInMiddleOfLine {
    padding-left: 5px;
  }
  .title {
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .label {
    float: left;
    padding-right: 2px;
  }
  img {
    height: 100%;
    object-fit: contain;
  }
  li {
    text-decoration: none;
  }
  .button {
    margin: 5px;
    text-transform: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    font-size: $mediumFontDefault;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px 10px;
    text-align: left;
    vertical-align: middle;
    min-height: 30px;
    min-width: 50px;
    text-decoration: none;
  }
  .button:focus {
    outline: none;
  }
  .button:active {
    opacity: 0.6;
  }
  .btn-default {
    border: none;
  }
  .btn-primary {
    border: none;
  }
  .btn-secondary {
    border: none;
  }
  .disabledBtn,
  .blur,
  .blur * {
    opacity: 0.4;
    cursor: not-allowed !important;
  }
  .blurredText {
    opacity: 0.4;
  }
  select {
    float: left;
    margin: 5px;
    padding: 5px;
    outline: 0;
    border-radius: 6px;
  }
  input,
  textArea {
    padding: 6px;
    margin: 5px;
    outline: 0;
    width: 100%;
    border-radius: 6px;
    clear: both;
    resize: vertical;
  }
  .searchFormInline {
    .field {
      float: left;
    }
  }
  .searchFormInEssEdit {
    margin-bottom: 200px;
    .searchResultInEssEdit {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
    }
  }
  .labelAndField {
    display: flex;
    flex-direction: column;
    padding: 2px;
  }
  .labelAndText {
    display: flex;
    flex-direction: column;
    padding: 2px;
    .textValue {
      padding: 5px;
    }
  }
  .buttonField {
    &-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  ::placeholder {
    opacity: 0.7;
  }
  .inline {
    display: inline-flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    float: left;
  }
  .separateLines {
    display: flex;
    flex-direction: column;
  }
  .autoCompleteContainer {
    position: relative;
    margin: 5px;
    float: left;
    width: 30%;
  }
  .autoComplete {
    display: flex;
    text-align: left;
    margin: 0 5px 0 0;
    border-width: 1px;
    border-style: solid;
  }
  .autoComplete-list {
    display: block;
    padding: 10px;
    max-height: 50vh;
    overflow-y: auto;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 17px;
    li {
      padding: 10px;
      cursor: pointer;
      display: block;
    }
  }
  input:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  .noPageHere {
    padding-top: 50px;
    text-align: center;
  }
  .globalMessage {
    padding: 10px;
    position: fixed;
    z-index: 4000;
    left: 0;
    text-align: center;
    bottom: 0;
    width: 100vw;
    min-height: 65px;
    transition: visibility 0.5s, opacity 0.5s;

    &.fadeOut {
      opacity: 0;
      visibility: hidden;
    }
    &.fadeIn {
      opacity: 1;
      visibility: visible;
    }
  }
  .fieldInfo {
    padding: 2px;
    width: 90%;
    text-align: center;
    margin: 5px 10px;
  }
  .closeIcon {
    &-card {
      right: 10px;
      top: 10px;
      @include clickable();
    }
    &-popup {
      cursor: pointer;
      text-align: right;
      padding-top: 5px;
      padding-right: 5px;
      padding-bottom: 5px;
      @include clickable();
    }
    &-menu {
      position: absolute;
      top: 15px;
      right: 15px;
      @include clickable();
    }
  }
  .popUpContainer {
    @include backgroundOverlay(
      $position: fixed,
      $background: rgba(255, 255, 255, 0.35)
    );
    display: flex;
    justify-content: center;
    .popUp {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 400px;
      z-index: 2000;
      border-style: none;
      border-radius: 5px;
      padding-bottom: 20px;
      .popUpComponent {
        max-height: 75vh;
        max-width: 900px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        .popUpCloseIcon {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .popUpBody {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px;
          .changeAccount {
            max-width: 400px;
          }
          .searchFormInline {
            display: flex;
            justify-content: space-around;
          }
          .textField {
            width: 100%;
          }
          .title {
            padding-bottom: 2px;
            text-align: center;
          }
          .button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .organizationTree {
            width: 100%;
            .tree {
              @include treeNode();
            }
          }
          @include licenseExtender();
          .addressForms {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .addressForm {
              width: 45%;
            }
            .visiting {
              float: left;
            }
            .invoice {
              float: right;
            }
          }
          .confirmBoxText {
            text-align: center;
            padding-bottom: 10px;
            white-space: pre-wrap;
          }
          .autologinlinkwrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
          }
        }
      }
    }
  }

  .react-time-picker__inputGroup {
    clear: both;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-time-picker__inputGroup__amPm {
    @include clickable();
  }
  .react-time-picker__wrapper {
    margin: 2px;
  }
  table.labelTextTable {
    padding: 5px;
    tbody {
      tr.labelTextRow {
        .tableLeftLabel {
          font-weight: bold;
        }
        .tableLeftLabel,
        .tableRightText {
          padding: 10px;
          border-bottom: 1px solid;
        }
      }
    }
  }
  table.responsiveTable {
    table-layout: fixed;
    width: 100%;
    height: auto;
    border-collapse: collapse;
    thead {
      th {
        padding: 0;
        border-collapse: collapse;
        padding: 5px;
      }
    }
    tbody {
      tr {
        td {
          border-collapse: collapse;
          padding: 7px;
          word-wrap: break-word;
          &:nth-child(1) {
            border-left: none;
          }
          .icons {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .tabledata-centered .radioField {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobileIcon {
    display: none;
  }
  .menuType {
    border-width: 1px;
    border-style: solid;
  }
  .menuLinkType {
    background: none !important;
    border-color: transparent !important;
  }
  .menuList {
    .menuItems {
      display: flex;
      justify-content: flex-end;
      margin: 3px 1px -2px -44px;
      li {
        margin-right: 2px;
        margin-left: 2px;
        div {
          padding: 5px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .logoutHeader {
    .iconContainer {
      justify-content: flex-end !important;
    }
  }
  .header {
    padding: 5px;
    width: 100%;
    height: $defaultHeaderHeight;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    select {
      padding: 2px;
      outline: 0;
    }
    .userDetails {
      font-size: $smallFontDefault;
      list-style: none;
    }
    .appLogo {
      align-items: baseline;
      justify-content: space-around;
      flex-direction: column;
      padding: 0 5px;
      font-size: $smallFontDefault;
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .rettsLogo {
        svg {
          width: 158px;
          height: 80px;
          transform: translateY(-5px);
        }
      }
    }
    .appUser {
      width: 60vw;
      display: flex;
      align-items: center;
      justify-content: center;
      .user {
        width: 50%;
        height: 100%;
        float: left;
        padding: 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
    .infoBox {
      .infoTextWithNoTitle {
        width: calc(100% - 20px);
        margin-top: -35px;
      }
    }
    .iconContainer {
      display: flex;
      justify-content: flex-end;
      margin-right: 30px;
      align-items: center;
      .headerIcon {
        cursor: pointer;
        display: flex;
        transition: 0.2s;
        position: relative;
        padding: 6px;
        margin: 0 5px;
        svg {
          margin: 1px 10px;
        }
      }
      .menuIcon:hover,
      .active {
        opacity: 1;
        border-radius: 4px;
        transition: 0.2s;
      }
      .badge {
        position: absolute;
        right: 0;
        border-radius: 46px;
        width: 20px;
        height: 20px;
        top: 0px;
        font-size: $xsFontDefault;
        display: flex;
        padding: 5px;
        justify-content: center;
        align-items: center;
      }
    }
    @include backgroundOverlay($position: fixed, $background: transparent);
    .sidenav {
      font-size: $smallFontDefault;
      white-space: pre;
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 10000;
      top: 0;
      right: 0;
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 60px;
      .sidenavItem,
      .logout {
        padding: 5px 50px;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
        .link {
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .sidenavOpen {
      width: 250px !important;
    }
  }
  .routes {
    padding: 5px 20px;
    width: 100%;
    min-height: calc(100vh - #{$defaultHeaderHeight} - #{$defaultFooterHeight});
    height: auto;
    font-size: $mediumFontDefault;
    .prevPageClickIcon {
      width: fit-content;
      svg {
        @include clickable();
      }
    }
    .history {
      margin: 0px auto;
      .historyForm {
        display: flex;
        flex-direction: column;
        max-width: 900px;
        min-width: 700px;
        margin: 10px auto;
        .radioField {
          justify-content: center;
          margin-bottom: 10px;
        }
        .date {
          display: flex;
          flex-direction: row;
          margin-bottom: 15px;
          align-self: center;
        }
        .searchResult {
          margin: 20px;
        }
        .exportDownload {
          width: auto;
        }
      }
    }

    .login {
      .loginContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        .loginContent {
          width: 500px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          padding: 40px;
          margin: 60px;
          border-radius: 3px;
          border-width: 1px;
          border-style: solid;

          input {
            width: 350px;
            align-self: center;
          }
          button {
            align-self: center;
          }
        }
      }
    }
    .editor {
      display: flex;
      flex-direction: column;
      margin-bottom: 100px;
      .prevNextButtons {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .prevNextButtonContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      .editHeader {
        margin-bottom: 20px;
      }
      .editSection {
        display: flex;
        flex-direction: row;
        .essLocal {
          margin-top: -1px;
          margin-left: 8px;
          .generalProcessActionCard {
            margin-top: -10px;
          }
          .section {
            .processActionInput {
              max-width: 300px;
              min-height: 70px;
            }
          }
        }
      }
    }
    .defaultProcessActionsCheckbox {
      width: fit-content;
    }
    .rettsProcessAction {
      min-height: 40px;
      background-color: #283742;
      padding: 8px;
      border-radius: 4px;

      button {
        margin-top: -4px;
      }
    }
    .processActions {
      display: inline-flex;
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: flex-start;
      float: left;

      .processActionsDescription {
        width: 60%;
        display: inline-block;
      }

      .processActionsButtons {
        display: inline-block;
      }
    }

    .infoTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .notFound {
      padding: 5px;
    }
    .essRangeFields {
      width: 100%;
      .labelAndField {
        width: 50%;
        float: left;
      }
    }
    .export {
      max-width: 900px;
      margin: 0 auto;
      .systemLanguage {
        display: flex;
        flex-direction: column;
        select {
          width: fit-content;
        }
      }
      .essExport {
        display: flex;
        flex-direction: column;
        .extentDropdown {
          width: fit-content;
        }
        .checkOptions {
          width: 100%;
          .layoutOptions {
            width: 50%;
            float: left;
          }
          .tablesOfContent {
            width: 50%;
            float: left;
          }
        }
      }
      .exportDownloads {
        width: 100%;
      }
    }
    .exportDownload {
      display: flex;
      justify-content: left;
      border-radius: 3px;
      margin: 5px;
      padding: 15px;
      width: 48%;
      float: left;

      .icon {
        width: 80px;
      }
    }
    .manageUsers {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      .manageUsersButtons {
        width: 100%;
      }
      .accounts {
        width: 100%;
        height: fit-content;
        .region {
          width: 100%;
          height: fit-content;
          float: left;
        }
        .accountsTree {
          width: 100%;
          height: fit-content;
          float: left;
          .organizationTree {
            width: 100%;
            .tree {
              @include treeNode();
            }
          }
        }
      }
      .accountDetails {
        width: 100%;
        height: fit-content;
        float: left;
        .users,
        .licenses,
        .accountInfo {
          flex-direction: column;
        }
        .products {
          width: 100%;
          display: inline-block;
          .availableProducts {
            width: 50%;
            float: left;
            .availableProduct {
              display: flex;
              align-items: center;
              justify-content: center;
              .availableProductName {
                width: 75%;
                float: left;
                padding: 5px 0 7px 0;
              }
            }
          }
          .assignProduct {
            width: 50%;
            float: left;
          }
        }
        .accountInfo {
          .visitingAddress,
          .invoiceAddress {
            width: 50%;
            float: left;
          }
        }
      }
    }
    .registerForm {
      align-items: center;
      max-width: 400px !important;
      .labelAndField {
        width: 80%;
      }
      .radioField {
        justify-content: center;
      }
      .buttonField-center {
        margin: 20px;
      }
      .privacyPolicy {
        margin-top: 20px;
      }
    }
    .riskLevel- {
      &text- {
        &1 {
          color: $riskLevel1 !important;
        }
        &2 {
          color: $riskLevel2 !important;
        }
        &3 {
          color: $riskLevel3 !important;
        }
        &4 {
          color: $riskLevel4 !important;
        }
        &5 {
          color: $riskLevel5 !important;
        }
      }
      &icon {
        margin: 2px;
        padding: 2px;
      }
      &icon- {
        &1 {
          background: $riskLevel1 !important;
          svg {
            fill: white;
          }
        }
        &2 {
          background: $riskLevel2 !important;
          svg {
            fill: white;
          }
        }
        &3 {
          background: $riskLevel3 !important;
          svg {
            fill: white;
          }
        }
        &4 {
          background: $riskLevel4 !important;
          svg {
            fill: white;
          }
        }
        &5 {
          background: $riskLevel5 !important;
          svg {
            fill: white;
          }
        }
      }
      &border- {
        &1 {
          border-color: $riskLevel1 !important;
        }
        &2 {
          border-color: $riskLevel2 !important;
        }
        &3 {
          border-color: $riskLevel3 !important;
        }
        &4 {
          border-color: $riskLevel4 !important;
        }
        &5 {
          border-color: $riskLevel5 !important;
        }
      }
      &bg- {
        &1 {
          background: $riskLevel1 !important;
        }
        &2 {
          background: $riskLevel2 !important;
        }
        &3 {
          background: $riskLevel3 !important;
        }
        &4 {
          background: $riskLevel4 !important;
        }
        &5 {
          background: $riskLevel5 !important;
        }
        &empty- {
          &1 {
            height: 35px;
            background: linear-gradient(
              to top right,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 48%,
              $riskLevel1 50%,
              rgba(0, 0, 0, 0) 52%,
              rgba(0, 0, 0, 0) 100%
            );
          }
          &2 {
            height: 35px;
            background: linear-gradient(
              to top right,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 48%,
              $riskLevel2 50%,
              rgba(0, 0, 0, 0) 52%,
              rgba(0, 0, 0, 0) 100%
            );
          }
          &3 {
            height: 35px;
            background: linear-gradient(
              to top right,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 48%,
              $riskLevel3 50%,
              rgba(0, 0, 0, 0) 52%,
              rgba(0, 0, 0, 0) 100%
            );
          }
          &4 {
            height: 35px;
            background: linear-gradient(
              to top right,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 48%,
              $riskLevel4 50%,
              rgba(0, 0, 0, 0) 52%,
              rgba(0, 0, 0, 0) 100%
            );
          }
          &5 {
            height: 35px;
            background: linear-gradient(
              to top right,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 48%,
              $riskLevel5 50%,
              rgba(0, 0, 0, 0) 52%,
              rgba(0, 0, 0, 0) 100%
            );
          }
        }
      }
    }
    .expanded_container {
      height: auto;
    }
    .collapsible_container {
      height: 75px;
      overflow: hidden;
    }
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px;
      margin: 10px auto;
      max-width: 900px;
      border-radius: 3px;
      border-width: 1px;
      border-style: solid;
      height: fit-content;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      word-break: keep-all;
    }
    .dateTimePicker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 5px;
      .datePickerLabel {
        min-width: 100px;
        margin-right: 10px;
      }
      .picker-container {
        justify-content: space-between;
        display: flex;
        width: 100%;
        flex-direction: column;
        input {
          margin-left: 0px;
        }
      }
    }
    .horizontalDivider {
      border: 0;
      border-bottom-width: 1px;
      border-style: solid;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .verticalDivider {
      border: 0;
      border-right-width: 1px;
      border-style: solid;
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;
    }
    .subTitle {
      font-size: $largeFontDefault;
      font-weight: bold;
    }
    .label {
      float: left;
      padding-right: 2px;
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    .productSelector {
      float: left;
    }
    .grid {
      width: 100%;
      display: flex;
      align-items: stretch;
      flex-flow: row wrap;
      margin: 0 auto;
      .col {
        &_1 {
          width: calc(100% - 20px);
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
        &_2 {
          width: calc(50% - 20px);
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
        &_3 {
          width: calc(33.33333% - 20px);
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
        &_4 {
          width: calc(25% - 20px);
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
        &_5 {
          width: calc(20% - 10px);
          display: flex;
          justify-content: space-between;
          flex-direction: row;
        }
      }
    }
    .publishBtn {
      flex-direction: row;
      width: max-content;
      .button {
        justify-content: center;
      }
    }
    .essCard {
      width: 100%;
      //reusing this esscard, so let's have it outside
      .essCardSticky {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #3a4b59;
        padding-bottom: 2px;
      }
      .statusFieldContainer {
        padding-bottom: 2px;
      }
      .infoLayerBorder {
        border-width: 1px !important;
      }
      font-size: $mediumFontDefault;

      .essCardTitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 20px;
        width: 100%;
        .essTitleCheckbox {
          display: flex;
          margin-left: 16px;
          width: fit-content;
          align-items: center;
        }
        .essTitle {
          width: 50%;
          text-align: left;
        }
        .versionText {
          width: inherit;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .editIcon-card {
          width: 5%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          @include clickable();
        }
        .closeIcon-card {
          width: 5%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          @include clickable();
        }
      }
      .essTitle {
        margin-left: 20px;
        margin-right: 20px;
      }
      .versionText {
        margin-right: 35px;
      }
      .searchPhrases {
        margin-top: 0;
        margin-bottom: 0;
      }

      .cardRow {
        display: flex;
        flex-direction: row;
        align-items: normal;

        .leftDiv {
          padding: 20px;
          width: 50%;
          border-right: 1px solid;
          ul {
            margin: 0;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
        .rightDiv {
          padding: 20px;
          width: 50%;
          .content {
            padding: 10px;
            display: grid;
            .relatedLink {
              clear: both;
              span {
                float: left;
              }
            }
          }
        }
      }
      .section {
        margin: 20px;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        .sectionTitle {
          padding: 5px 10px;
        }
        .sectionContent {
          white-space: pre-wrap;
          padding: 10px 35px;
          .processAction {
            border: 2px solid;
            border-left: 15px solid;
            padding: 5px;
            border-radius: 5px;
          }
          div {
            margin-top: 5px;
            margin-bottom: 5px;
            white-space: pre-wrap;
          }
          hr {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        .textField {
          max-width: 100%;
        }
      }
      .definitionBlock {
        padding: 20px;
        white-space: pre-wrap;
      }

      .vpBlock {
        padding: 20px;
      }
    }
    .search {
      width: 100%;
      display: flex;
      flex-direction: column;
      .searchCardsGrid {
        .cardess {
          height: 80vh;
        }
      }
    }
    .searchForm {
      display: flex;
      justify-content: center;
      padding-left: 5px;
      padding-right: 5px;
    }
    .essList {
      display: flex;
      flex-direction: column;
      align-items: center;
      .listContent {
        display: flex;
        max-width: 800px;
        .left {
          display: inline-flex;
          flex: 0.6;
          margin: 10px;
          flex-direction: column;
          .essClassifyList {
            padding-bottom: 5px;
          }

          .vpList {
            padding-bottom: 5px;
          }

          .links {
            display: inline-flex;
            flex-direction: column;
            .link {
              white-space: nowrap;
            }
          }
        }

        .right {
          flex: 2;
          margin: 0px 10px;

          .essSpList {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .essSortByFilter {
              float: right;
              padding: 5px;
              display: flex;
            }
            thead {
              th:nth-child(1) {
                width: 20%;
              }
            }
          }
        }
      }
    }
    .downloadableEssPackagesTable {
      padding-top: 25px;
    }
    .vpCard {
      .essCardTitle {
        .essTitle {
          width: 90%;
        }
      }
    }
    .vpCard,
    .essSpList {
      .responsiveTable {
        thead {
          th:nth-child(1) {
            width: 10%;
          }
        }
        tbody {
          td.pivoted:nth-child(1) {
            text-align: center;
          }
        }
      }
    }
    .changelog {
      width: 100px;
    }
    .handlVersion {
      display: flex;
      flex-direction: column;
      justify-content: left;
      margin: 10px auto;
      max-width: 900px;
      .tabs {
        display: inline-flex;
        flex-direction: row;
        margin-top: 10px;
        .tab {
          border: 1px solid;
          border-bottom: none;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          position: relative;
          list-style: none;
          padding: 8px 12px;
          cursor: pointer;
          width: max-content;
          margin: 0px 1px;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
          .toolTipFullText {
            margin-top: 8px;
          }
        }
      }
      .tabPanels {
        display: flex;
        justify-content: left;
        .tabPanel {
          margin: 0px;
          .card {
            margin: 0px;
          }
        }
      }
    }
    .ongoingVersion {
      .ongoingtab {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .publishForm .download-link {
      display: flex;
      align-items: center;
    }
  }

  .essExport {
    .radioButtons .radioField {
      padding: 0;
      .radioButton {
        margin: 0;
      }

      .radioLabel {
        padding-left: 5px;
      }
    }
  }

  .footer {
    padding: 5px;
    position: relative;
    width: 100%;
    height: $defaultFooterHeight;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .predicareLogo {
      svg {
        height: 18px;
        width: 70px;
        transform: translateY(5px);
      }
    }
  }
  .xxl,
  .xxl label,
  .xxl input,
  .xxl li,
  .xxl .md-text {
    font-size: $xxlFontDefault;
  }
  .xl,
  .xl label,
  .xl input,
  .xl li,
  .xl .md-text {
    font-size: $xlFontDefault;
  }
  .l,
  .l label,
  .l input,
  .l li,
  .l .md-text {
    font-size: $largeFontDefault;
  }
  .m,
  .m label,
  .m input,
  .m li,
  .m .md-text {
    font-size: $mediumFontDefault;
  }
  .s,
  .s label,
  .s input,
  .s li,
  .s .md-text {
    font-size: $smallFontDefault;
  }
  .xs,
  .xs label,
  .xs input,
  .xs li,
  .xs .md-text {
    font-size: $xsFontDefault;
  }
  .xxs,
  .xxs label,
  .xxs input,
  .xxs li,
  .xxs .md-text {
    font-size: $xxsFontDefault;
  }
  #searcPhrasesContainer {
    margin-top: 0px;
  }
  #searcPhrasesContainerSticky {
    margin-top: 0px;
    margin-bottom: 18px;
  }
  #processActionsSticky {
    margin-top: 0;
  }
  #essTitle {
    align-items: center;
  }
  #sticky-checkbox {
    width: auto;
  }
}
