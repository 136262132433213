@media (min-width: $tabletMinWidth) and (max-width: $tabletMaxWidth) {
    body {
        .header{
            padding: 3px;
            .userDetails{
                display: none;
            }
            .appLogo{
                position: relative;
                left: 0;
                transform: translateX(0);
            }
        }
        .toolTip .toolTipFullText {
            max-width:  400px;
        }
        .routes{
            padding: 15px;
            font-size: $mediumFontTablet;
            .login{
                .textField{
                    width: 50%;
                }
            }
            .grid {
                .col {
                    &_3 {
                        width: calc(50% - 20px);
                    }
                    &_4 {
                        width: calc(50% - 20px);
                    }
                    &_5 {
                        width: calc(50% - 10px);
                    }
                }
            }
            .search {
                .essCard {
                    font-size: $mediumFontTablet;
                    .cardRow{
                        flex-direction: column;
                        .leftDiv{
                            border-right: none;
                            border-bottom: 1px solid;
                            width: 100%;
                            ul {
                                width: 100%;
                            }
                        }
                        .rightDiv{
                            width: 100%;
                        }
                    }
                }
            }
            .essList{
                .essClassifyList{
                    width: 100%;
                }
                .vpList{
                    width: 100%;
                }
                .essSpList{
                    width: 100%;
                }
            }
            .overview {
                flex-direction: column;
                .left {
                    width: 100%;
                }
                .right {
                    width: 100%;
                }
            }
            .handlVersion {
                .toolTipFullText {
                    display: none;
                }
            }
        }
        .footer{
            padding: 3px;
        }
        .xxl, .xxl label, .xxl input, .xxl li, .xxl .md-text {
            font-size: $xxlFontTablet;
        }
        .xl, .xl label, .xl input, .xl li, .xl .md-text{
            font-size: $xlFontTablet;
        }
        .l, .l label, .l input, .l li, .l .md-text{
            font-size: $largeFontTablet;
        }
        .m, .m label, .m input, .m li, .m .md-text {
            font-size: $mediumFontTablet;
        }
        .s, .s label, .s input, .s li, .s .md-text {
            font-size: $smallFontTablet;
        }
        .xs, .xs label, .xs input, .xs li, .xs .md-text {
            font-size: $xsFontTablet;
        }
        .xxs, .xxs label, .xxs input, .xxs li, .xxs .md-text {
            font-size: $xxsFontTablet;
        }
    }
}
