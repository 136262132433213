$baseFontSize : 1rem;
//default
$xxlFontDefault: 3rem;
$xlFontDefault: 1.85rem;
$largeFontDefault: 1.5rem;
$mediumFontDefault: .9rem;
$smallFontDefault: .8rem;
$xsFontDefault: .7rem;
$xxsFontDefault: .6rem;
//tablet
$xxlFontTablet: 3rem;
$xlFontTablet: 1.95rem;
$largeFontTablet: 1.75rem;
$mediumFontTablet: 0.83rem;
$smallFontTablet: 0.8rem;
$xsFontTablet: .7rem;
$xxsFontTablet: .65rem;
//mobile
$xxlFontMobile: 2rem;
$xlFontMobile: 1.75rem;
$largeFontMobile: 1.5rem;
$mediumFontMobile: 0.8rem;
$smallFontMobile: 0.7rem;
$xsFontMobile: .6rem;
$xxsFontMobile: .5rem;
