@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    body {
        .routes {
            .editSection {


                .card.preview.ie11-hack {
                    width: 100%;
                }

                .essLocal.ie11-hack {
                    width: 90%;
                    .card {
                        max-width: inherit;
                    }
                }

                .section.ie11-hack {
                    .sectionContent {
                        display: flex;

                        .sectionContent-text-wrapper {
                            flex-basis: 100%;
                        }
                    }
                }
            }

            .essList {
                width: 800px;
                margin-left: auto;
                margin-right: auto;
                .listContent{
                    width: 800px;
                }
            }

            .tab .toolTipFullText {
                min-width: 400px;
            }

            .riskLevel- {
                &bg- {
                    &empty- {
                        &1 {
                            height: 35px;
                            background-image: url('//images/red_line.png');
                            background-size: 100% 100%;
                        }
                        &2 {
                            height: 35px;
                            background-image: url('//images/orange_line.png');
                            background-size: 100% 100%;
                        }
                        &3 {
                            height: 35px;
                            background-image: url('//images/yellow_line.png');
                            background-size: 100% 100%;
                        }
                        &4 {
                            height: 35px;
                            background-image: url('//images/green_line.png');
                            background-size: 100% 100%;
                        }
                        &5 {
                            height: 35px;
                            background-image: url('//images/blue_line.png');
                            background-size: 100% 100%;
                        }
                    }
                }
            }

        }

        .header {
            display: block;

            .appLogo {
                position: absolute;
                top: 0;
            }

            .iconContainer {
                position: absolute;
                right: 0;
                top: 0;
                .headerIcon {
                    margin-top: 15px;
                }
            }
        }
    }
}
