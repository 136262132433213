

@import '../riskColor.scss';
@media screen and (max-width: $mobileMaxWidth) {
    ::-webkit-scrollbar {
        display: none;
    }
    body {
        .not-available-for-small-screens {
            display: none;
        }
        table.responsiveTable {
            tbody{
                tr {
                    border: none;
                    td{
                        div:nth-child(2){
                            z-index: 100;
                            position: relative;
                        }
                    }
                }
            }
        }

        .autoCompleteContainer {
            width: 90%;
            margin: 0 10px 0 0;
        }
        .mobileIcon{
            display: block;
        }
        .menuLinkType{
            border-width: 1px;
            border-style: solid;
        }
        .menuLeft{
            top: $mobileHeaderHeight+1px;
            left: 0px;
        }
        .menuRight{
            top: $mobileHeaderHeight+1px;
            right: 0px;
        }
        .menuList{
            justify-content: flex-end;
            display: flex;
            .menuItems{
                flex-direction: column;
                position: absolute;
                display: none;
            }
            .showMenuItems{
                display: block !important;
            }
        }
        .logoutHeader {
            flex-direction: row !important;
            .appUser {
                width: 80vw !important;
                .user{
                    width: 0px !important;
                    display: none !important;
                }
            }
            .iconContainer{
                width: 30vw !important;
            }
        }
        .toolTip .toolTipFullText {
            max-width:  200px;
        }
        .popUpContainer {
            .popUp{
                position: relative;
                margin: 30%;
                .popUpComponent{
                    .popUpBody{
                        .searchFormInline{
                            .field{
                                width: 96%;
                            }
                        }
                        .changeAccount {
                            margin: 0 auto;
                            max-width: 90%;
                        }
                        .addressForms{
                            display: flex;
                            flex-direction: column;
                            .addressForm{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .header{
            padding: 1px;
            height: $mobileHeaderHeight;
            font-size: $smallFontMobile;
            flex-direction: column;
            .userDetails{
                display: none;
            }
            .appLogo {
                position: relative;
                height: 50%;
                .rettsLogo {
                    display: flex;
                    justify-content: center;
                    a {
                        img {
                            height: 35px;
                        }
                    }
                }
            }
            .iconContainer{
                height: 50%;
                display: flex;
                justify-content: space-between;
                margin: 0 15px 5px;
                align-items: center;
                overflow-x: auto;
                overflow-y: hidden;
                div{
                    svg{
                        margin:2px;
                    }
                }
                .headerIcon{
                    margin: 6px;
                }
            }
        }
        .routes{
            font-size: $mediumFontMobile;
            min-height: calc(100vh - #{$mobileHeaderHeight} - #{$mobileFooterHeight});
            padding: 0 !important;
            .grid {
                .col_1 {
                    width: calc(100% - 10px)
                }
            }
            .card {
                padding: 0;
            }
            .vpBlock {
                padding: 0 !important;
                table.responsiveTable {
                    td.pivoted {
                        padding-left: calc(30% + 10px) !important;
                    }

                    tbody {
                        tr {
                            td:nth-child(2) {
                                background: $riskLevel1 !important;
                                margin: 2px 0 2px 0;
                                font-size: 1.2em
                            }
                            td:nth-child(3) {
                                background: $riskLevel2 !important;
                                margin: 0 0 2px 0;
                                font-size: 1.2em
                            }
                            td:nth-child(4) {
                                background: $riskLevel3 !important;
                                margin: 0 0 2px 0;
                                font-size: 1.2em
                            }
                            td:nth-child(5) {
                                background: $riskLevel4 !important;
                                margin: 0 0 2px 0;
                                font-size: 1.2em
                            }
                        }
                    }
                }
            }
            .essCard {
                ul {
                    padding-inline-start: 20px;
                }
                .section {
                    margin: 5px;
                    .sectionContent {
                        padding: 5px 5px !important;
                        .processActionTitle{
                            font-size: 1.3em;
                        }
                    }
                }

            }
            .essRangeFields{
                .labelAndField{
                    width: 100%;
                }
            }
            .export{
                .essExport{
                    .extentDropdown{
                        width: 96%;
                    }
                    .checkOptions{
                        .layoutOptions{
                            width: 100%;
                        }
                        .tablesOfContent{
                            width: 100%;
                        }
                    }
                }
                .exportDownloads{
                    width: 98%;
                    .exportDownload{
                        width: 100%;
                    }
                }
            }
            .login{
                .textField{
                    width: 100%;
                }
            }
            .registerForm {
                max-width: 300px !important;
            }
            select, input, .radioField>span{
                margin: 5px;
            }
            .manageUsers{
                .accounts{
                    .region{
                        width: 100%;
                    }
                    .department{
                        width: 100%;
                    }
                }
                .accountDetails{
                    .products{
                        .availableProducts{
                            width: 100%;
                        }
                        .assignProduct{
                            width: 100%;
                        }
                    }
                    .accountInfo{
                        .visitingAddress, .invoiceAddress{
                            width: 100%;
                        }
                    }
                }
            }
            .grid {
                .col {
                    &_2 {
                        width: calc(100% - 20px);
                    }
                    &_3 {
                        width: calc(100% - 20px);
                    }
                    &_4 {
                        width: calc(100% - 20px);
                    }
                    &_5 {
                        width: calc(100% - 10px);
                    }
                }
            }
            .search {
                max-height: fit-content;
                .essCard {
                    font-size: $mediumFontMobile;
                    .section {
                        .sectionContent {
                            padding: 5px 15px;
                        }
                    }
                    .cardRow {
                        flex-direction: column;
                        .leftDiv{
                            width: 100%;
                            ul {
                                width: 100%;
                                border-bottom: 1px solid;
                                border-right: 0px;
                            }
                        }
                        .rightDiv {
                            width: 100%;
                        }
                    }
                }
                .searchCardsGrid{
                    flex-direction: column-reverse;
                    height:auto;
                    .cardess{
                        height: auto;
                        .scrollable{
                            overflow: unset;
                        }
                    }
                }
            }
            .essList{
                flex-direction: column;
                padding: 1px;
                .left{
                    width: 100%;
                    height: auto;
                    .essClassifyList{
                        width: 50%;
                        float: left;
                    }
                    .vpList{
                        width: 50%;
                        float: left;
                    }
                }
                .right{
                    width: 100%;
                    .essSpList{
                        .essSortByFilter{
                            float: right;
                            padding: 1px;
                        }
                    }
                }
            }
            .editContainer{
                .editor{
                    flex-direction: column;
                    .editSection{
                        display: flex;
                        flex-direction: column;
                    }
                }
                .card{
                    width: 100%;
                }
                .searchFormInEssEdit{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: calc(100% - 20px);
                }
            }
            .cardess{
                height:fit-content;
            }
            .handlVersion {
                .toolTipFullText {
                    display: none;
                }
            }
        }
        .popUp{
            width: 96vw;
            left: 3vw;
        }
        .footer{
            padding: 1px;
            height: $mobileFooterHeight;
            .predicareLogo{
                height: 15px;
            }
        }
        .xxl, .xxl label, .xxl input, .xxl li, .xxl .md-text {
            font-size: $xxlFontMobile;
        }
        .xl, .xl label, .xl input, .xl li, .xl .md-text{
            font-size: $xlFontMobile;
        }
        .l, .l label, .l input, .l li, .l .md-text{
            font-size: $largeFontMobile;
        }
        .m, .m label, .m input, .m li, .m .md-text {
            font-size: $mediumFontMobile;
        }
        .s, .s label, .s input, .s li, .s .md-text {
            font-size: $smallFontMobile;
        }
        .xs, .xs label, .xs input, .xs li, .xs .md-text {
            font-size: $xsFontMobile;
        }
        .xxs, .xxs label, .xxs input, .xxs li, .xxs .md-text {
            font-size: $xxsFontMobile;
        }

        .searchForm {
            display:block !important;
        }

        .loginContainer {
            .loginContent {
                .rememberMe {
                    padding-left: 0 !important;
                }
            }
        }
    }
}
